export const mySocials = [
    {
      link: "https://github.com/KoMeSa",
      icon: "fa-brands fa-github",
      text: "Ruslan Komesa/ KoMeSa",
    },
    {
      link: "https://www.facebook.com/profile.php?id=100022332641111",
      icon: "fa-brands fa-facebook",
      text: "Ruslan Komesa",
    },
    {
      link: "https://t.me/MiKaSo",
      icon: "fa-brands fa-telegram",
      text: "@MiKaSo",
    },
    {
      link: "https://mail.google.com/",
      icon: "fa-solid fa-envelope",
      text: "komesarus777@gmail.com",
    },
  ];